import image from '../Assets/Images/favicon.webp'
import { BsPerson } from "react-icons/bs";
import { BsTelephone } from "react-icons/bs";
import { BsPinMap } from "react-icons/bs";
import { BsCardText } from "react-icons/bs";

const JobCard = (item) => {
    return (
        <>
            <div className="shadow bg-gray-100 w-full h-fit px-3 py-3 rounded mt-6">
                <div className="flex flex-col">
                    <div className="flex flex-row items-center pb-4">
                        <img height={40} width={40} src={image} />
                        <p className='pl-4 font-medium'>{item.data.carMake} {item.data.carModel} | {item.data.customerProblem}</p>
                    </div>

                    <p className='font-medium'>Customer Details</p>
                    <div className='flex flex-row pt-2 mb-4 items-center flex-wrap'>
                        <BsPerson className='text-gray-500' />
                        <p className='pl-1 pr-2'>{item.data.customerName} |</p>
                        <BsTelephone className='text-gray-500' />
                        <p className='pl-1 pr-2'>{item.data.customerPhonenumber} |</p>
                        <BsPinMap className='text-gray-500' />
                        <p className='pl-1'>{item.data.nearestCity} ,</p>
                        <p className='pl-1'>{item.data.customerPostCode}</p>
                    </div>

                    <p className='font-medium'>Car Details</p>
                    <div className='flex flex-row pt-2 items-center flex-wrap mb-4'>
                        <BsCardText className='text-gray-500' />
                        <p className='pl-1'>{item.data.customerCarRegistration}</p>
                    </div>

                    {!item.data.customMessage.trim() == '' ? <>
                        <p className='font-medium'>Custom Message</p>
                        <div className='flex flex-row pt-2 items-center flex-wrap'>
                            <BsCardText className='text-gray-500' />
                            <p className='pl-1'>{item.data.customMessage}</p>
                        </div>

                    </> : <></>}

                </div>
            </div>

            <div onclick={() => window.open(`tel:${item.customerPhonenumber}`)} className='w-full md:w-1/2 lg:w-1/2 cursor-pointer py-1 px-4 bg-gray-700 text-center text-white rounded font-medium justify-self-center mt-4'>Call Now</div>
        </>
    )
}

export default JobCard