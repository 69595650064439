import React from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import favicon from '../Assets/Images/favicon.webp'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const NavigationBar = () => {

  const navigate = useNavigate()


  const navigation = [
    { name: 'Home', href: '#home' },
    { name: 'How it works', href: '#how-it-works' },
    { name: 'About us', href: '#about-us' },
    { name: 'Reviews', href: '#reviews' },
  ]

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login', { replace: true });
  }

  return (
    <>
      <Popover>
        <div className="relative px-4 pt-6 sm:px-6 lg:px-8" id='home'>
          <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
            <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
              <div className="flex w-full items-center justify-between md:w-auto">
                <NavLink to={'/'}>
                  <span className="sr-only">FindMyMechanic</span>
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={favicon}
                      alt=""
                    />
                  </div>
                </NavLink>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3b9ddd]">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                  {item.name}
                </a>
              ))}

            </div>
            <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
            {localStorage.getItem('token') ? <div onClick={() => handleLogout()} className='bg-gray-700 py-1 px-4 rounded text-white cursor-pointer font-medium'>Log Out</div> : <div></div>}
            </div>

          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={favicon}
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3b9ddd]">
                    <span className="sr-only">Close main menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </a>

                ))}
                {localStorage.getItem('token') ? <div onClick={() => handleLogout()} className='bg-gray-700 py-2 px-4 rounded text-white  font-medium  cursor-pointer'>Log Out</div> : <div></div>}                </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  )
}

export default NavigationBar